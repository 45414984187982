import React from 'react'
import {Link} from 'gatsby'
import {Box, Button, Heading, Text} from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
    author: {
        display: `inline-block`,
        color: `alpha`
    },
    occupation: {
        mb: 4
    },
    specialty: {
        color: `text`,
        mb: 4
    }
}

const HeroContent = () => (
    <>
        <Heading as='h1' variant='h1'>
            Élaborons <Text sx={styles.author}>ensemble</Text>
        </Heading>
        <Heading as='h2' variant='h2' sx={styles.occupation}>
            Franck Anso, CTO as a service
        </Heading>
        <Box variant='buttons.group'>
            <Button variant='white' as={Link} to='/about'>
                À propos
            </Button>
            <Button as={Link} to='/contact'>
                Contactez-moi
            </Button>
        </Box>
    </>
)

export default HeroContent;
